const english = [
    24,
    57,
    127,
    8,
    2,
    28,
    222,
    30,
    150,
    179,
    128,
    111,
    172,
    212,
    194,
    201,
    92933,
    156,
    79,
    216,
    183,
    138,
    103,
    81,
    185,
    227,
    232,
    34967,
    170,
    152,
    46
]
export default {
    english: english
}