<template>
  <div class="col-12 mb-2">
    <div class="row">
      <div class="col-12 mt-4">
        <h3>{{ getTrans('messages.select_audience') }}</h3>
      </div>
      <div class="col-12 mb-2">
        <span>{{ getTrans('messages.package_howto') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <el-tabs
          tab-position="top"
          type="border-card"
          :stretch="true"
          class="w-100"
        >
          <el-tab-pane label="Networks">
            <target-tab-pane
              ref="networks"
              :selectedvalues="selected"
              :maxPerPage="100"
              :items="getNetworks"
              :title="'networks'"
              :labelname="'title'"
              :valuename="'id'"
              @targetupdated="targetsUpdated"
            ></target-tab-pane>
            <!-- TargetUpdated overwrites selected -->
          </el-tab-pane>
          <el-tab-pane label="Sources">
            <target-tab-pane
              :view="mobileView ? 'checkbox' : 'tableview'"
              :selectedvalues="selected"
              :items="getSearchResults"
              :title="'sources'"
              :labelname="'name'"
              :valuename="'id'"
              :columnWl="[
                'network',
                'name',
                'daily_volume',
                'won',
                'avg_bid',
                'score',
              ]"
              @targetupdated="targetsUpdated"
              ><div class="row mt-4">
                <div class="col-12 col-lg-2 text-center mb-2">
                  <el-input
                    v-model="sourceSearchWord"
                    :placeholder="getTrans('messages.search_for_websites')"
                  ></el-input>
                </div>
                <div class="col-12 col-lg-6">
                  <el-select v-model="selectnetworks" multiple>
                    <el-option value="" label="All networks">
                      {{ getTrans('messages.all_networks') }}
                    </el-option>
                    <el-option
                      v-for="item in getNetworks"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div></div
            ></target-tab-pane>
          </el-tab-pane>
          <el-tab-pane label="Countries"
            ><target-tab-pane
              ref="countries"
              :selectedvalues="selected"
              :maxPerPage="1000"
              :items="getCountries"
              :title="'countries'"
              :labelname="'name'"
              :valuename="'id'"
              @targetupdated="targetsUpdated"
              ><el-button
                class="mt-2"
                type="primary"
                @click="selectAll('countries', 'english')"
                >{{ getTrans('messages.all_english') }}</el-button
              ></target-tab-pane
            >
          </el-tab-pane>
          <el-tab-pane label="Device brands">
            <target-tab-pane
              :selectedvalues="selected"
              :items="getDeviceBrands"
              :title="'device_brands'"
              :labelname="'name'"
              :valuename="'id'"
              @targetupdated="targetsUpdated"
            ></target-tab-pane>
          </el-tab-pane>
          <el-tab-pane label="Device types">
            <target-tab-pane
              :selectedvalues="selected"
              :maxPerPage="1000"
              :items="getDeviceTypes"
              :title="'device_types'"
              :labelname="'name'"
              :valuename="'id'"
              @targetupdated="targetsUpdated"
            ></target-tab-pane>
          </el-tab-pane>
          <el-tab-pane label="Browsers">
            <target-tab-pane
              :items="getBrowsers"
              :maxPerPage="1000"
              :title="'browsers'"
              :labelname="'name'"
              :valuename="'id'"
              :selectedvalues="selected"
              @targetupdated="targetsUpdated"
            ></target-tab-pane>
          </el-tab-pane>
          <el-tab-pane label="Os">
            <target-tab-pane
              :selectedvalues="selected"
              :maxPerPage="1000"
              :items="getOs"
              :title="'os'"
              :labelname="'name'"
              :valuename="'id'"
              @targetupdated="targetsUpdated"
            ></target-tab-pane>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="col-12 d-flex mt-4 mx-0 px-0">
        <el-slider
          refs="slider"
          v-model="sliderBid"
          :format-tooltip="
            (s) => {
              return '$' + s;
            }
          "
          :step="0.01"
          :min="minBid"
          :max="maxBid"
          class="col-10"
          @change="
            () => {
              updateBid(sliderBid);
            }
          "
        >
        </el-slider>
        <el-input
          class="col-2"
          v-model="inputBid"
          @change="
            () => {
              updateBid(inputBid);
            }
          "
          ><template #prepend>$</template></el-input
        >
      </div>
      <div class="col-12">
        <div>
          <span
            >{{ getTrans('messages.price_pr_1000') }}: {{ pricePerTokens }}
            {{ getTrans('messages.tokens') }}</span
          >
        </div>
        <div>
          <span>
            {{ getTrans('messages.estimated_traffic_pr_token') }}:
            {{ estimatedPerTokens }} {{ getTrans('messages.users') }}
          </span>
        </div>
      </div>
      <div class="col-12 mt-4">
        <h5>{{ getTrans('messages.bidinfo') }}</h5>
        <span>{{ getTrans('messages.bidinfo_text') }}</span>
      </div>
      <div class="col-12 text-center">
        <base-button
          type="secondary"
          v-if="showDailyVolume && !targetsHasBeenUpdated"
          >{{ getTrans('messages.estimated_daily_volume') }}:
          {{ daily_volume }}</base-button
        >
        <base-button
          type="secondary"
          v-if="targetsHasBeenUpdated || !showDailyVolume"
          @click="estimateDailyVolume"
          :loading="calculating_dv"
          >{{ getTrans('messages.estimate_daily_volume') }}</base-button
        >
        <base-button type="primary" @click="saveBid">{{
          getTrans('messages.save')
        }}</base-button>
      </div>
      <div class="col-12 text-center" v-if="calculating_dv">
        <div class="row">
          <div>
            <span>{{ getTrans('messages.estimating') }}...</span>
          </div>
        </div>
        <div class="row">
          <base-progress
            type="primary"
            :height="12"
            :label="'E'"
            :value="progress"
            class="w-100 mb--3"
          ></base-progress>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TargetTabPane from './TargetTabPane';
import TargetCollections from './TargetCollections';
import { mapGetters } from 'vuex';
import {
  ElButton,
  ElTabs,
  ElTabPane,
  ElInput,
  ElOption,
  ElSlider,
  ElSelect,
} from 'element-plus';

export default {
  name: 'target-audience',
  emits: ['saveBid'],
  components: {
    [ElButton.name]: ElButton,
    [ElTabs.name]: ElTabs,
    [ElTabPane.name]: ElTabPane,
    [ElInput.name]: ElInput,
    [ElOption.name]: ElOption,
    [ElSlider.name]: ElSlider,
    [ElSelect.name]: ElSelect,
    TargetTabPane,
  },
  props: {
    bid: {
      type: Object,
    },
  },
  data() {
    return {
      maxBid: 5,
      minBid: 0.25,
      window: { width: 0, height: 0 },
      mobileView: false,
      currentBid: 0.25,
      sliderBid: 0.25,
      inputBid: 0.25,
      selected: {},
      sourceSearchWord: '',
      selectnetworks: [],
      progress: 20,
      daily_volume: 0,
      showDailyVolume: false,
      calculating_dv: false,
      targetsHasBeenUpdated: true,
      showSlider: false,
    };
  },
  watch: {
    bid: {
      deep: true,
      handler() {
        this.updateTargetPanes();
      },
    },
    sourceSearchWord: {
      handler() {
        this.searchSource();
      },
    },
    selectnetworks: {
      handler() {
        this.searchSource();
      },
    },
    sliderBid() {
      this.inputBid = this.sliderBid;
    },
    /*"window.width"() {
      this.mobileView = this.window.width < 1000 ? true : false;
    },*/
  },
  computed: {
    ...mapGetters('Os', ['getOs']),
    ...mapGetters('Browser', ['getBrowsers']),
    ...mapGetters('Camsite', ['getCamsitesForUser', 'getCamsites']),
    ...mapGetters('Country', ['getCountries']),
    ...mapGetters('Plan', ['getPackages']),
    ...mapGetters('Source', ['getSearchResults']),
    ...mapGetters('DeviceBrand', ['getDeviceBrands']),
    ...mapGetters('DeviceType', ['getDeviceTypes', 'getDeviceTypeById']),
    ...mapGetters('Network', ['getNetworks']),

    pricePerTokens() {
      return (this.currentBid * 10).toFixed(2);
    },
    estimatedPerTokens() {
      return Math.floor(100 / this.currentBid);
    },
  },
  methods: {
    updateTargetPanes() {
      if (this.bid) {
        if (this.bid.targeting) {
          this.selected = this.bid.targeting;
        } else {
          this.selected = {};
        }
        this.updateBid(this.bid?.bid);
      } else {
        this.updateBid(0.25);
        this.selected = {};
      }
    },
    selectAll(mod, collections) {
      if (!this.selected[mod]) {
        this.selected[mod] = [];
      }
      var newValues = [];
      TargetCollections[collections].forEach((element) => {
        this.selected[mod].indexOf(element) < 0
          ? newValues.push(element)
          : null;
      });
      this.selected[mod] = newValues;
    },
    targetsUpdated(target, val) {
      this.targetsHasBeenUpdated = true;
      var newTarget = {};
      newTarget[target] = val;
      Object.assign(this.selected, newTarget);
    },
    searchSource() {
      if (this.sourceSearchWord) {
        this.$store
          .dispatch('Source/search', {
            search: this.sourceSearchWord,
            network: this.selectnetworks,
          })
          .then(() => {
            this.$forceUpdate();
          });
      }
    },
    saveBid() {
      this.$emit('saveBid', { bid: this.currentBid, targeting: this.selected });
    },
    /*handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },*/
    estimateDailyVolume() {
      this.targetsHasBeenUpdated = false;
      this.progress = 0;
      this.calculating_dv = true;
      var interval = setInterval(() => {
        if (Math.random() > 0.9) {
          this.progress += 5;
        }
        if (this.progress >= 85) {
          clearInterval(interval);
        }
      }, 100);
      this.$store
        .dispatch('Plan/dailyVolume', { type: 1, targeting: { network: 6 } })
        .then((response) => {
          this.daily_volume = response.data.daily_volume;
          this.showDailyVolume = true;
          this.calculating_dv = false;
        })
        .catch(() => {
          this.daily_volume = 0;
          this.showDailyVolume = false;
          this.calculating_dv = false;
        });
    },
    updateBid(newValue) {
      try {
        let val = Number.parseFloat(newValue);
        if (val > this.maxBid) {
          this.val = this.maxBid;
        } else if (val < this.minBid) {
          this.val = this.minBid;
        }
        this.currentBid = val;
        this.sliderBid = val;
        this.inputBid = val;
        /* eslint-disable-next-line no-empty */
      } catch {}
    },
  },
  mounted() {
    this.estimateDailyVolume();
    this.updateTargetPanes();
    this.showSlider = true;
  },
};
</script>
<style scoped>
.targetCheckBox {
  margin-left: 0px !important;
  margin-right: 5px;
  overflow: hidden;
}

.targetCheckboxContainer {
  max-height: 400px;
  overflow: auto;
}
</style>
