<template>
  <div class="row">
    <div class="col-12">
      <base-button size="md" type="primary" @click="setShow"> Add </base-button>
      <base-button size="md" type="danger" @click="setShow(false)">Delete</base-button>
    </div>
  </div>
  <template v-if="show">
    <div class="row">
      <div class="col-12">
        <template v-if="selectedvalues[title]">
          <template v-for="(title, key) in selectedvalues[title]" :key="key">
            <base-button class="mt-1" disabled type="info">
              {{ capitalizeFirstLetter(title) }}
              <span
                @click="removeKey(key)"
                class="badge badge-md badge-circle badge-floating badge-default border-white"
                ><i class="fas fa-times"></i
              ></span>
            </base-button>
          </template>
        </template>
        <slot name="search">
          <el-input
            class="mt-4"
            :placeholder="getTrans('messages.search')"
            v-model="searchword"
          ></el-input>
        </slot>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4">
        <base-button type="success" @click="selectAll">
          {{ getTrans('messages.all' + title) }}
        </base-button>
        <base-button type="info" @click="clearSelected">
          {{ getTrans('messages.clear') }}
          {{ title ? getTrans('messages.' + title) : getTrans('messages.filter') }}
        </base-button>
        <slot></slot>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-if="view == 'checkbox'">
          <el-checkbox-group
            size="medium"
            v-model="checkedTargets"
            class="targetCheckBox-container"
          >
            <template v-for="item in searchResultPage" :key="item[valuename]">
              <el-checkbox
                class="targetCheckBox"
                :label="item[valuename] + ''"
                border
                size="medium"
                @change="
                  () => {
                    clickedOne(item);
                  }
                "
                >{{ capitalizeFirstLetter(item[labelname]) }}</el-checkbox
              >
            </template>
          </el-checkbox-group>
        </div>
        <div class="table-responsive" v-else-if="view == 'tableview'">
          <el-table
            :stripe="true"
            ref="multipleTable"
            :data="searchResultPage"
            @select="selectTabelItem"
            @select-all="selectTabelItem"
            @sort-change="tableSort"
            :row-key="valuename"
            ><el-table-column type="selection" :reserve-selection="true"> </el-table-column>
            <el-table-column
              :fixed="true"
              v-for="(key, i) in columnWl"
              :key="i"
              :prop="key"
              :label="key"
              :sortable="true"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="row" v-if="Math.ceil(searchResult.length / maxPerPage) > 1">
      <div class="col-12 justify-content-center d-flex">
        <base-pagination
          v-model="currentPage"
          :total="searchResult.length"
          :per-page="maxPerPage"
          @update:modelValue="selectPage"
        ></base-pagination>
      </div>
    </div>
  </template>
</template>
<script>
import StringFormatter from '@/mixins/StringFormatter';
import { ElCheckbox, ElCheckboxGroup, ElInput, ElTable, ElTableColumn } from 'element-plus';
export default {
  mixins: [StringFormatter],
  name: 'target-tab-pane',
  emits: ['targetupdated'],
  props: {
    items: {
      type: Array,
    },
    labelname: {
      type: String,
    },
    valuename: {
      type: String,
    },
    maxPerPage: { default: 35, type: Number },
    title: {
      type: String,
    },
    selectedvalues: {
      default: () => {
        return {};
      },
      type: Object,
    },
    view: {
      default: () => {
        return 'checkbox';
      },
      type: String,
    },
    columnWl: Array,
    refresh: {
      type: Number,
    },
  },
  components: {
    ElCheckbox,
    ElCheckboxGroup,
    ElTable,
    ElTableColumn,
    ElInput,
  },
  data() {
    return {
      show: false,
      searchword: '',
      checkedTargets: [],
      currentPage: 1,
      sortProp: '',
      sortOrder: '',
      tableKeys: [],
    };
  },
  watch: {
    selectedvalues: {
      handler() {
        this.refreshSelected();
      },
      deep: true,
      immediate: true,
    },
    searchResultPage: {
      deep: true,
      handler() {
        this.updatedTableKeys();
      },
    },
  },
  computed: {
    searchResult() {
      if (!this.items) return [];
      var lowercaseSearchword = this.searchword.toLowerCase();
      var result = [];
      if (this.searchword) {
        result = this.items.filter((i) =>
          i[this.labelname].toLowerCase().includes(lowercaseSearchword)
        );
      } else {
        result = this.items;
      }
      if (this.sortProp) {
        result.sort((a, b) => {
          let com = 0;
          if (a[this.sortProp] > b[this.sortProp]) com = 1;
          if (a[this.sortProp] < b[this.sortProp]) com = -1;
          if (this.sortOrder == 'descending') com = -1 * com;
          return com;
        });
      }
      return result;
    },
    searchResultPage() {
      if (this.searchResult && this.searchResult.length) {
        return this.searchResult.slice(
          (this.currentPage - 1) * this.maxPerPage,
          (this.currentPage - 1) * this.maxPerPage + this.maxPerPage
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    setShow(val = true) {
      this.show = val;
    },
    delete() {
      this.checkedTargets = [];
      this.show = false;
    },
    tableSort(...prop) {
      this.sortProp = prop[0].prop ? prop[0].prop + '' : '';
      this.sortOrder = prop[0].order ? prop[0].order + '' : '';
    },
    selectTabelItem(items) {
      this.checkedTargets = [];
      items.forEach((i) => {
        if (i) this.selectItem(i[this.valuename]);
      });
    },
    selectItem(id) {
      this.checkedTargets.push(id);
      this.updateSelected();
    },
    selectPage(p) {
      this.currentPage = p;
    },
    updatedTableKeys() {
      this.tableKeys = [];
      if (this.searchResultPage.length && this.columnWl) {
        this.tableKeys = [];
        this.tableKeys = this.columnWl.forEach((c) => {
          if (Object.keys(this.searchResultPage[0]).indexOf(c) >= 0) {
            this.tableKeys.push(c);
          }
        });
      }
    },
    refreshSelected() {
      if (this.selectedvalues && this.selectedvalues[this.title]) {
        this.checkedTargets = Object.keys(this.selectedvalues[this.title]);
      } else {
        this.checkedTargets = [];
      }
    },
    clickedOne(item) {
      if (
        this.selectedvalues[this.title] &&
        this.selectedvalues[this.title][item[this.valuename]]
      ) {
        this.removeItem(item);
      } else {
        this.addItem(item);
      }
    },
    selectArray(items) {
      var newSelected = {};
      for (var item in items) {
        newSelected[item[this.valuename]] = item[this.labelname];
      }
      this.$emit('targetupdated', this.title, newSelected);
    },
    addArray(items) {
      var newSelected = this.selectedvalues[this.title] ? this.selectedvalues[this.title] : {};
      for (var item in items) {
        newSelected[item[this.valuename]] = item[this.labelname];
      }
      this.$emit('targetupdated', this.title, newSelected);
    },
    addItem(item) {
      var newSelected = this.selectedvalues[this.title] ? this.selectedvalues[this.title] : {};
      newSelected[item[this.valuename]] = item[this.labelname];
      this.$emit('targetupdated', this.title, newSelected);
    },
    selectAll() {
      var newSelected = {};
      for (let i in this.searchResult) {
        newSelected[this.searchResult[i][this.valuename]] = this.searchResult[i][this.labelname];
      }
      this.$emit('targetupdated', this.title, newSelected);
    },
    clearSelected() {
      this.$emit('targetupdated', this.title, {});
    },
    removeItem(item) {
      this.removeKey(item[this.valuename]);
    },
    removeKey(key) {
      var newSelected = this.selectedvalues[this.title] ? this.selectedvalues[this.title] : {};
      delete newSelected[key];
      this.$emit('targetupdated', this.title, newSelected);
    },
  },
};
</script>
<style scoped>
.targetCheckBox {
  margin-left: 0px !important;
  margin-right: 5px;
  overflow: hidden;
}
.targetCheckBox-container {
  max-height: 259px;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.el-checkbox__label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
