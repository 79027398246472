<template>
  <div class="card card-style text-white">
    <div class="card-header bg-gradient-pink text">
      <span class="float-left">{{ getTrans("messages.bid") }}:</span>
      <span class="float-right">{{ bid.bid }}</span>
    </div>
    <div class="card-body bg-gradient-pink">
      <div class="row" v-for="targetKey in Object.keys(bid.targeting)" :key="targetKey">
        <div class="col-12 col-lg-6">
          <span class="text item"
            >{{ getTrans("messages."+targetKey) }}:</span
          >
        </div>
        <div
          class="col-12 col-lg-6 text-right"
          v-if="
            !bid.targeting[targetKey] ||
            Object.keys(bid.targeting[targetKey]).length == 0
          "
        >
          <span class="text">
            {{ getTrans("messages.all_"+targetKey) }}
          </span>
        </div>
        <div class="col-12 col-lg-6 text-right" v-else>
          <span
            class="bid-item text"
            v-for="key in Object.keys(bid.targeting[targetKey]).slice(0, 2)"
            :key="key"
          >
            {{
              capitalizeFirstLetter(
                bid.targeting[targetKey][key]
              )
            }}</span
          ><el-popover
            v-if="Object.keys(bid.targeting[targetKey]).length > 2"
            class="bid-item text"
            placement="bottom"
            trigger="click"
            content="This option seems to have more than 2 options selected. If you want to have a full detailed view of your choices, click on the button below 'view details' to see more."
          >
            <base-button size="mini" type="info"
              ><span
                ><i class="fa fa-plus pr-2"></i>
                {{ Object.keys(bid.targeting[targetKey]).length - 2 }}
                <i
                  class="far fa-question-circle"
                ></i></span></base-button
          ></el-popover>
        </div>
      </div>
    </div>
    <div class="card-footer bg-gradient-pink">
      <div class="col-12 text-center">
        <el-button-group>
          <el-button
            type="primary"
            size="mini"
            round
            @click="editBid()"
            >{{ getTrans("messages.edit") }}</el-button
          >
          <el-button
            type="warning"
            size="mini"
            round
            @click="copyBid()"
            >{{ getTrans("messages.duplicate") }}</el-button
          ><el-button
            type="danger"
            size="mini"
            round
            @click="deleteBid()"
            >{{ getTrans("messages.delete") }}</el-button
          >
          <el-button
            type="info"
            size="mini"
            round
            
            >{{ getTrans("messages.view_details") }}</el-button
          >
        </el-button-group>
      </div>
    </div>
  </div>
</template>

<script>
import StringFormatter from '../../../mixins/StringFormatter.vue'
import { ElButton, ElButtonGroup } from 'element-plus'
export default {
  emits: ['edit', 'delete', 'copy'],
  mixins: [StringFormatter],
  components: {
    ElButton,
    ElButtonGroup,
  },
  props: {
    bid: {
      type: Object,
      required: true,
    },
  }, 
  methods: {
    editBid() {
      this.$emit('edit')
    },
    copyBid() {
      this.$emit('copy')
    },
    deleteBid() {
      this.$emit('delete')
    }
  }
}
</script>

<style>

</style>