<template>
  <div class="row">
    <div class="col-lg-8">
      <diva-card>
        <div class="row">
          <div class="col">
            <h1>{{ getTrans('messages.package_details') }}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <base-input
              v-model="name"
              :placeholder="getTrans('messages.name')"
              :label="getTrans('messages.package_title')"
              :error="getError('name')"
            ></base-input>
          </div>
          <div class="col-12">
            <base-input
              v-model="description"
              :placeholder="getTrans('messages.description')"
              :label="getTrans('messages.package_description')"
              :error="getError('description')"
            ></base-input>
          </div>
          <div class="col-12">
            <h3 class="">
              {{ getTrans('messages.target_audience') }}
            </h3>
            <span>{{ getTrans('messages.target_audience_sub') }}</span
            ><br />
          </div>
          <div
            class="col-12 col-lg-4 pb-2"
            v-for="(bid, index) in bids"
            :key="index"
          >
            <bid-card
              :pack="pack"
              :bid="bid"
              @edit="
                () => {
                  editBid(index);
                }
              "
            ></bid-card>
          </div>
          <div class="col-12 col-lg-4 pb-2">
            <div
              class="card shadow card-style add-package-card"
              @click="showTargeting = true"
            >
              <base-button type="primary" class="m-auto"
                ><i class="fa fa-plus mr-2 my-auto"></i
                >{{ getTrans('messages.add_bid') }}</base-button
              >
            </div>
          </div>
          <div class="col-12 mt-2 text-center">
            <base-button type="info" @click="goToPackages">{{
              getTrans('messages.cancel')
            }}</base-button>
            <base-button type="primary" @click="savePackage">{{
              getTrans('messages.save')
            }}</base-button>
          </div>
        </div>
      </diva-card>
    </div>
    <div class="col-lg-4">
      <diva-card>
        <div class="col-12 text-center">
          <span
            ><strong>{{ getTrans('messages.package_youtube') }}</strong></span
          ><br />
          <div class="p-4">
            <base-button type="youtube" icon
              ><a
                class="text-white"
                href="https://www.youtube.com/watch?v=R4mXVlhASvM"
              >
                <span class="btn-inner--icon"
                  ><i class="fab fa-youtube"></i
                ></span>
                <span class="btn-inner--text">youtube</span>
              </a>
            </base-button>
          </div>
        </div>
        <div class="row pt-2 pb-2 text-center">
          <div class="text-center">
            <h3>{{ getTrans('messages.how_it_works') }}</h3>
            <span class="p-2">{{ getTrans('messages.target_help') }}</span>
            <br />
            <div class="p-3">
              <base-button type="success">
                {{ getTrans('messages.start_guide') }}
              </base-button>
            </div>
            <br />
            <div class="pb-1">
              <span
                ><strong>
                  {{ getTrans('messages.traffic_reports') }}</strong
                ></span
              >
            </div>
            <br />
            <base-button class="mr-2" type="primary" size="small"
              ><a
                class="text-white"
                href="https://bi.divatraffic.com/public/dashboards/DYNXGznrxJ78qQw5ie4sBcHxnZm9UDEad5o5dAnA"
              >
                {{ getTrans('messages.sources') }}
              </a>
            </base-button>
            <a
              class="text-white"
              href="https://bi.divatraffic.com/public/dashboards/dp3ZRfumEBFWctfi7x93TTtPtTA2n547YMcDXrLj"
              ><base-button class="mr-1" type="primary" size="small">
                {{ getTrans('messages.countries') }}
              </base-button></a
            >
            <base-button class="ml-1" type="primary" size="small"
              ><a
                class="text-white"
                href="https://bi.divatraffic.com/public/dashboards/cG3NHt8F2ovT8yuZM8L24SQcIHqQbqZn1M0hFk0x"
              >
                {{ getTrans('messages.browsers') }}
              </a>
            </base-button>
          </div>
        </div>
      </diva-card>
    </div>
  </div>
  <el-dialog
    v-model="showTargeting"
    :title="getTrans('messages.target_audience')"
    :width="'80%'"
    :before-close="targetingClose"
    destroy-on-close
    center
    custom-class="modal-card"
  >
    <target-audience
      ref="targetAudience"
      @saveBid="saveBid"
      :bid="currentlyEditingBid"
    ></target-audience>
  </el-dialog>
</template>
<script>
// Components
import { mapGetters } from 'vuex';
import TargetAudience from '@/views/Plan/Components/TargetAudience.vue';
import Errors from '@/mixins/Errors';
import { ElButton, ElButtonGroup, ElPopover, ElDialog } from 'element-plus';
import StringFormatter from '../../mixins/StringFormatter.vue';
import DivaCard from '../../components/Content/DivaCard.vue';
import BidCard from './Components/BidCard.vue';
export default {
  name: 'createpackages',
  mixins: [StringFormatter, Errors],
  components: {
    TargetAudience,
    [ElButton.name]: ElButton,
    [ElButtonGroup.name]: ElButtonGroup,
    [ElDialog.name]: ElDialog,
    [ElPopover.name]: ElPopover,
    DivaCard,
    BidCard,
  },
  props: {
    copyPackage: {
      type: String,
    },
    editPackage: {
      type: String,
    },
  },
  data() {
    return {
      name: '',
      description: '',
      bids: [],
      pack: {
        name: '',
        description: '',
        bids: [],
        targetingData: {},
        targeting: {},
      },
      showTargeting: false,
      currentlyEditingBid: null,
      currentlyEditingIndex: -1,
    };
  },
  computed: {
    ...mapGetters('User', { getUser: 'getData' }),
    ...mapGetters('Browser', ['getBrowsers', 'getBrowserById']),
    ...mapGetters('Os', ['getOs', 'getOsById']),
    ...mapGetters('Network', ['getNetworks', 'getNetworkById']),
    ...mapGetters('Country', ['getCountries', 'getCountryById']),
    ...mapGetters('Source', { getSources: 'getList' }),
    ...mapGetters('DeviceBrand', ['getDeviceBrands', 'getDeviceBrandById']),
    ...mapGetters('DeviceType', ['getDeviceTypes', 'getDeviceTypeById']),
    ...mapGetters('Plan', { errors: 'getErrors' }),
  },
  methods: {
    goToPackages() {
      this.$router.push({ name: 'Packages' });
    },
    //bid card functions
    editBid(index) {
      this.showTargeting = true;
      this.currentlyEditingBid = this.bids[index];
      this.currentlyEditingIndex = index;
    },
    copyBid(bid) {
      let bidCopy = this.bids[bid];
      delete bidCopy.id;
      this.bids.push(bidCopy);
    },
    deleteBid(b) {
      this.bids.splice(b, 1);
    },
    //save plan to server
    savePackage() {
      if (this.bids.length == 0) {
        this.$swal.fire({
          title: 'One bid required!',
          text: 'You must have atleast one bid to create a package!',
          type: 'warning',
        });
        return;
      }
      if (!this.name) {
        this.$swal.fire({
          title: 'Title required!',
          text: 'You must have a title to create a package!',
          type: 'warning',
        });
        return;
      }
      let s = this.createPlanForApi();
      var promises;
      if (this.editPackage) {
        s.id = this.pack.id;
        promises = this.$store.dispatch('Plan/save', s);
      } else {
        promises = this.$store.dispatch('Plan/create', s);
      }
      promises
        .then(() => {
          this.$toast.success(this.getTrans('messages.package_saved'));
          this.goToPackages();
        })
        .catch(() => {
          this.$toast.error(this.getTrans('messages.save_failed'));
        });
    },
    //update bids list on the current plan
    saveBid(bid) {
      if (this.currentlyEditingBid) {
        this.bids[this.currentlyEditingIndex] = bid;
      } else {
        this.bids.push(bid);
      }
      this.showTargeting = false;
      this.currentlyEditingBid = null;
      this.currentlyEditingIndex = -1;
    },
    //warning when closing dialogue
    targetingClose(close) {
      this.$swal
        .fire({
          showCancelButton: true,
          title: this.getTransChoise('messages.exit'),
          text: this.getTransChoise('messages.are_you_sure'),
          icon: 'info',
        })
        .then((result) => {
          if (result.isConfirmed) {
            close();
          }
        });
    },
    //returns complete package without targeting data
    createPlanForApi() {
      let plan = {
        name: this.name,
        description: this.description,
        type: 1,
        user_id: this.getUser.id,
        update_related_orders: 1,
      };
      plan.bids = this.bids.map((b) => {
        let targeting = {};
        for (const key in b.targeting) {
          targeting[key] = Object.keys(b.targeting[key]); //getting just IDs from targeting with labels
        }
        return { bid: b.bid, targeting };
      });
      return plan;
    },
    //put targeting data on bids
    loadPack(packString, deleteId = false) {
      let pack = JSON.parse(packString);
      Object.assign(this.pack, pack);
      if (deleteId) {
        delete this.pack.id;
      }
      this.pack.bids = [];
      for (const bid of pack.bids) {
        let bidWithData = {
          bid: bid.bid,
          targeting: {},
        };
        for (const key in bid.targeting) {
          bidWithData.targeting[key] = {};
          for (const id of bid.targeting[key]) {
            bidWithData.targeting[key][id] = pack.targetingData[key][id];
          }
        }
        this.pack.bids.push(bidWithData);
      }
      this.loadPackValues();
    },
    //loads pack info into inputs and cards for editing
    loadPackValues() {
      this.name = this.pack.name;
      this.description = this.pack.description;
      this.bids = this.pack.bids;
    },
  },
  mounted() {
    this.$store.dispatch('Platform/get');
    this.$store.dispatch('Camsite/get');
    this.$store.dispatch('Profile/getAll');
    this.$store.dispatch('Country/get');
    this.$store.dispatch('Network/GET');
    if (this.editPackage) {
      this.loadPack(this.editPackage);
    } else if (this.copyPackage) {
      this.loadPack(this.copyPackage, true);
    }
  },
};
</script>
<style scoped>
.card-style {
  min-width: 25% !important;
  height: 100%;
}
.add-package-card {
  width: 100%;
  text-align: center;
  font-size: 20rem;
  min-height: 200px;
}
.add-package-card i {
  margin: auto;
}
.bid-item {
  padding-right: 5px;
  white-space: break-spaces;
}
.text {
  font-size: 18px;
  font-weight: 500;
  color: white;
}
text-dot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 20px;
}
.text-alignment {
  text-align: right;
}
.modal-title {
  font-size: 30px;
  font-weight: 800;
}
.modal-text {
  font-size: 24px;
  font-weight: 500;
}
.modal-card {
  border-radius: 0.3rem;
}
</style>
